import { defineStore } from 'pinia'

export const useRegisterStore = defineStore({
  id: 'register',

  state: () => ({
    _agreePrivacy: null as boolean | null,
    _agreeThirdTerms: null as boolean | null,
    _agreeTerms: null as boolean | null,
    _agreeAdult: null as boolean | null,
    _agreeMarketing: null as boolean | null,
    _agreeMarketingAd: null as boolean | null,
    _verifiedToken: null as string | null,
    _phone: null as string | null,
    _oauthType: null as string | null,
    _oauthId: null as string | null,
    _marketingMethods: [] as string[],
    _email: null as string | null,
    _name: null as string | null,
  }),

  getters: {
    agreePrivacy: (state) => state._agreePrivacy,
    agreeTerms: (state) => state._agreeTerms,
    agreeThirdTerms: (state) => state._agreeThirdTerms,
    agreeAdult: (state) => state._agreeAdult,
    agreeMarketing: (state) => state._agreeMarketing,
    agreeMarketingAd: (state) => state._agreeMarketingAd,
    verifiedToken: (state) => state._verifiedToken,
    phone: (state) => state._phone,
    oauthType: (state) => state._oauthType,
    oauthId: (state) => state._oauthId,
    marketingMethods: (state) => state._marketingMethods,
    email: (state) => state._email,
    name: (state) => state._name,
  },

  actions: {
    clear() {
      this._agreeMarketing = null
      this._agreeMarketingAd = null
      this._agreeThirdTerms = null
      this._agreeTerms = null
      this._agreeAdult = null
      this._agreePrivacy = null
      this._verifiedToken = null
      this._phone = null
      this._oauthType = null
      this._oauthId = null
      this._marketingMethods = []
      this._email = null
      this._name = null
    },
    setAgreeMarketing(agreeMarketing: boolean) {
      this._agreeMarketing = agreeMarketing
    },
    setAgreeMarketingAd(agreeMarketingAd: boolean) {
      this._agreeMarketingAd = agreeMarketingAd
    },
    setAgreeThirdTerms(agreeThirdTerms: boolean) {
      this._agreeThirdTerms = agreeThirdTerms
    },
    setAgreeTerms(agreeTerms: boolean) {
      this._agreeTerms = agreeTerms
    },
    setAgreeAdult(agreeAdult: boolean) {
      this._agreeAdult = agreeAdult
    },
    setAgreePrivacy(agreePrivacy: boolean) {
      this._agreePrivacy = agreePrivacy
    },
    setVerifiedToken(verifiedToken: string | null) {
      this._verifiedToken = verifiedToken
    },
    setPhone(phone: string | null) {
      this._phone = phone
    },
    setLoginType(type: string) {
      this._oauthType = type
    },
    setOauthId(oauthId: string) {
      this._oauthId = oauthId
    },
    setMarketingMethods(marketingMethods: string[]) {
      this._marketingMethods = marketingMethods
    },
    setEmail(email: string) {
      this._email = email
    },
    setName(name: string) {
      this._name = name
    },
  },
})
